
/**
 * @description 计算一个包含任意规格组合的sku映射
 * @param skuList sku列表数据
 * @returns 
 */
export function computedSkuResultMap(skuList) {
  const map = {}
  
  skuList.forEach((sku) => {
    if (sku.stock <= 0) {
      return
    }

    const ids = sku.attrSet.map((item) => item.value)
    const keysArr = powerset(ids);

    keysArr.forEach((keyArr) => {
      const key = keyArr.join('_')
      const v = map[key];

      map[key] = v ? [...v, sku] : [sku]
    })
  })

  return map
}
/**
 * @description js求幂集
 * @param arr
 * @returns
 */
function powerset(arr) {
  const ps = [[]];

  for (let i = 0; i < arr.length; i++) {
    for (let j = 0, len = ps.length; j < len; j++) {
      ps.push(ps[j].concat(arr[i]));
    }
  }

  return ps;
}
/**
* @description 根据当前所选属性值，更新属性按钮的禁用状态 => map版本
* @param saleAttrs
* @param skuList
* @returns
*/
export function setAttrOptionStatus(attrList, skuMap) {
  // 1.获取已选规格集合{A}
  const selectedSet = attrList.reduce((arr, item) => {
    item.currentValue && (arr[item.attrLabel] = item.currentValue);
    return arr
  }, {})
  // 2.遍历所有待选规格
  attrList.forEach((attr) => {
    attr.options.forEach((option) => {
      if (option.isChecked) {
        return
      }
      // 3.待选项{x}与已选项{A}组成新的集合B = {A,x}
      const nextSelectSet = { ...selectedSet, [attr.attrLabel]: option.value }
      /* 
        4.将集合B的元素值拼一个字符串的key,去提前计算好的skuMap字典里查找
          若无查找结果，则此按钮需要置灰，反之亦然。
      */
      const valueArr = attrList.map(({ attrLabel }) => nextSelectSet[attrLabel]).filter(Boolean)
      const sku = skuMap[valueArr.join('_')]
      option.disabled = sku === undefined;
    })
  })
  return attrList
}

export function createSkuList(attrList,prevSkuList = []){
  const  skuList = [];//收集结果
  let id = 0;//生成skuId
  // 旧的SkuList转map，方便下方的复用判断
  const prevSkuMap = skuList2Map(prevSkuList);
  
  const loop = (rowIndex,prevOptions)=>{
    const attrItem = attrList[rowIndex];
    if(attrItem.options.length === 0){
      loop(rowIndex +1,prevOptions)
      return
    }
    for(const option of attrItem.options){
      const curOptions = prevOptions.concat({
        label:attrItem.attrLabel,
        value:option.value
      });
      if(rowIndex === attrList.length - 1){//判断如果是最后一层，那就是组合完整了，将结果收集到全局的容器里
        id ++;
        const key = curOptions.map(({value})=>value).join('_'); // 将sku的选项值用'_'连接起来组成一个key
        if(prevSkuMap[key]){// 如果改变前后的sku key相同，复用sku数据,避免数据覆盖
          skuList.push({
            ...prevSkuMap[key],
            id:`${id}`
          })
        }else{
          skuList.push({
            id:`${id}`,
            key,
            attrSet:curOptions,
            stock:0
          })
        }
      }else{
        loop(rowIndex +1,curOptions)
      }
    }
  }
  loop(0,[])
  return skuList
}

/**
 * @description sku列表数据转map,方便映射查找，判断sku数据对比复用
 * @param skuList  sku列表
 * @returns skuKey做键,sku数据做值的sku查找映射
 */
function skuList2Map(skuList){
  return skuList.reduce((map,sku)=>{
    map[sku.key] = sku;
    return map
  },{})
}

export const handleQuickSetSkuStock = (skuList,noneStockKeys)=>{
    skuList.forEach((item)=>{
    // 这里预设的都是没库存的
    if(noneStockKeys.includes(item.key)){
        item.stock = 10
    }else{
        item.stock = 0
    }
  })
  return skuList
}