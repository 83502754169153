<template>
  <div>
    <div class="bj">
      <div class="content">
        <div class="iconfont icon-close close" @click="delClick"></div>
        <div class="cont">
          <div class="return" @click="shangClick">
            <div class="iconfont icon-return"></div>
          </div>
          <div class="enter" @click="xiaClick">
            <div class="iconfont icon-enter"></div>
          </div>
          <a-carousel ref="carouselRef">
            <div class="imgs" v-for="item in list">
              <img :src="$imgUrl + item.path_name" alt="" srcset="" />
            </div>
          </a-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  props: ["list", "index"],
  watch: {},
  created() {},
  methods: {
    shangClick() {
      this.$refs.carouselRef.next();
    },
    xiaClick() {
      this.$refs.carouselRef.next();
    },
    delClick() {
      this.$emit("del", { show: false });
    },
  },
  mounted() {
    this.index = Number(this.index);
    if (this.list) {
      this.$refs.carouselRef.goTo(this.index, false);
    }
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.bj {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  width: 75vw;
  height: 60vh;
  border-radius: 10px;
  position: relative;
  top: -50px;
  .close {
    height: 50px;
    text-align: right;
    font-weight: 600;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
  }
  .cont {
    height: calc(60vh - 50px);
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    .imgs {
      height: calc(60vh - 50px);
      img {
        height: 100%;
        object-fit: contain;
      }
    }
    .return {
      width: 50px;
      z-index: 99;
      color: #fff;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconfont {
        font-size: 40px;
        cursor: pointer;
      }
    }
    .enter {
      width: 50px;
      z-index: 99;
      color: #fff;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconfont {
        font-size: 40px;
        cursor: pointer;
      }
    }
  }
}
</style>
