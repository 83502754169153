<template>
  <div>
    <div class="bj" @click="delClick">
      <div class="bjLeftCont" @click.stop="stop">
        <div class="headBox">
          <div class="name">用户评价</div>
          <div class="iconfont icon-close close" @click="delClick"></div>
        </div>
        <div class="pjList scroll" id="ysCont" @scroll="scrollEvent">
          <div class="pjLi" v-for="item in list">
            <div class="img">
              <img :src="$imgUrl + item.memberavatar" alt="" />
            </div>
            <div class="pjLiBox">
              <div class="pjHead">
                <div class="name">{{ item.membername }}</div>
                <div class="txt">
                  <div class="t">{{ item.time }}</div>
                  <div class="d">·</div>
                  <div class="star">
                    <div
                      class="iconfont icon-collection_fill"
                      v-for="chr in item.score"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="pjTxt">{{ item.content }}</div>
              <div class="pjImgs">
                <div
                  class="pjimg"
                  v-for="(chr, index) in item.resources"
                  @click="imgClick(item.resources, index)"
                >
                  <img :src="$imgUrl + chr.path_name" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ImgBig
      @del="imgDelClick"
      :list="imgList"
      :index="imgIndex"
      v-if="imgShow"
    />
  </div>
</template>

<script>
import { watch } from "less";
import ImgBig from "../component/imgBig.vue";

export default {
  components: {
    ImgBig,
  },
  name: "",
  data() {
    return {
      imgIndex: 0,
      imgList: [],
      imgShow: false,
      judge: true,
      page: {
        pageNo: 1,
        pageSize: 20,
      },
      list: [],
    };
  },
  props: ["id"],
  created() {},
  mounted() {
    this.listGet();
  },
  methods: {
    listGet() {
      if (!this.judge) {
        return;
      }
      this.judge = false;
      this.$get("/web/evaluation_gets", {
        ...this.page,
        id: this.id,
      }).then((res) => {
        console.log(res);
        this.judge = true;
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            e.time = this.timeago(e.create_time);
            this.list.push(e);
          }
          this.page.pageNo++;
        } else {
          if (this.page.pageNo != 1) {
            this.$message.warning("已展示全部内容");
          }
        }
      });
    },
    scrollEvent() {
      let top = document.querySelector("#ysCont").scrollTop;
      let height = document.querySelector("#ysCont").scrollHeight;
      let offHeight = document.querySelector("#ysCont").offsetHeight;
      if (offHeight + top - height >= 0) {
        this.peoGet();
        // 需要执行的代码
      }
    },
    timeago(dateTimeStamp) {
      let minute = 1000 * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let week = day * 7;
      let month = day * 30;
      let time1 = new Date().getTime(); //当前的时间戳
      dateTimeStamp = this.$moment(dateTimeStamp).format("YYYY/M/D HH:mm");
      let time2 = Date.parse(new Date(dateTimeStamp)); //指定时间的时间戳
      let time = time1 - time2;
      let result = null;
      if (time < 0) {
        result = "--";
      } else if (time / month >= 1) {
        result = parseInt(time / month) + "月前";
      } else if (time / week >= 1) {
        result = parseInt(time / week) + "周前";
      } else if (time / day >= 1) {
        result = parseInt(time / day) + "天前";
      } else if (time / hour >= 1) {
        result = parseInt(time / hour) + "小时前";
      } else if (time / minute >= 1) {
        result = parseInt(time / minute) + "分钟前";
      } else {
        result = "刚刚";
      }
      return result;
    },
    stop() {},
    delClick() {
      this.$emit("del", { show: false });
    },
    imgClick(list, i) {
      this.imgList = list;
      this.imgIndex = i;
      this.imgShow = true;
    },
    imgDelClick() {
      this.imgShow = false;
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.bjLeftCont {
  padding: 25px 5px 25px 25px;
  box-sizing: border-box;
}
.headBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  .name {
    font-weight: 600;
    font-size: 20px;
  }
  .close {
    font-weight: 600;
    font-size: 20px;
    color: #333;
    cursor: pointer;
  }
}
.pjList {
  box-sizing: border-box;
  padding-right: 20px;
  overflow: auto;
  height: calc(100% - 40px);
  .pjLi {
    display: flex;
    margin-bottom: 15px;
    .img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
    .pjLiBox {
      width: calc(100% - 60px);
      margin-left: 15px;
      box-sizing: border-box;
      border-bottom: 1px solid #ebebeb;
      .pjHead {
        height: 45px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .name {
          color: #11192d;
        }
        .txt {
          color: #7c889c;
          display: flex;
          align-items: center;
          .d {
            margin: 0 5px;
          }
          .star {
            margin-top: 1px;
            display: flex;
            .iconfont {
              color: #e70303;
            }
          }
        }
      }
      .pjTxt {
        margin-bottom: 10px;
        margin-top: 5px;
      }
      .pjImgs {
        overflow: hidden;
        .pjimg {
          float: left;
          width: calc(20% - 8px);
          margin-right: 10px;
          margin-bottom: 10px;
          padding-top: calc(20% - 8px);
          position: relative;
          cursor: pointer;
          img {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
          }
        }
        .pjimg:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
